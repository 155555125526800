import React from 'react'
import { useRouter } from 'next/router'

import { useCMSPageBanner } from '@mindfulchefuk/features/CMS/hooks/useCMSPageBanner'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { CMSPageApiPathWithBanners } from '@mindfulchefuk/features/CMS/services/getCMSPage'
import { useCMSBannerRedirect } from '@mindfulchefuk/features/CMS/hooks/useCMSBannerRedirect'
import { CMSBannerLinkWrapper } from '@mindfulchefuk/features/CMS/components/CMSBannerLinkWrapper'
import {
  instrumentChooseRecipesBannerClicked,
  instrumentR2GBannerClicked,
} from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentBanners'
import { ProductType } from '@mindfulchefuk/analytics'

export const ShopPromotionalBanner = ({
  page,
}: {
  page: CMSPageApiPathWithBanners
}) => {
  const router = useRouter()
  const r2gTabActive = router.query?.page && router.query?.page !== 'recipes'
  const banner = useCMSPageBanner({
    page,
    productCategory: r2gTabActive && (router.query?.page as ProductType),
  })
  const { redirectURL } = useCMSBannerRedirect(banner?.redirectConfig)

  if (!banner || !router.isReady) return null

  const eventParamsR2Go: Parameters<typeof instrumentR2GBannerClicked>[0] = {
    bannerName: banner?.title,
    campaignName: banner?.campaign ? banner.campaign.title : '',
    pageSource:
      page === 'page-shop-onboarding'
        ? 'ready to go category page'
        : 'account ready to go category page',
    productCategory: router.query?.page as ProductType,
  }

  const eventParamsRecipes: Parameters<
    typeof instrumentChooseRecipesBannerClicked
  >[0] = {
    bannerName: banner?.title,
    campaignName: banner?.campaign ? banner.campaign.title : '',
    pageSource: 'account recipes page',
    customerPreferences: [''],
  }

  return (
    <CMSBannerLinkWrapper
      href={redirectURL}
      mb={24}
      onClick={() =>
        r2gTabActive
          ? instrumentR2GBannerClicked(eventParamsR2Go)
          : instrumentChooseRecipesBannerClicked(eventParamsRecipes)
      }
    >
      <CMSImage
        data-testid="cms-shop-promotional-banner"
        src={banner.image.src}
        sources={banner.image.sources}
        alt={banner.image.altText}
      />
    </CMSBannerLinkWrapper>
  )
}
