import { analyticsEvents } from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentProductAnnouncementViewed = (
  productAnnouncement: string
) => {
  const { name, data } =
    analyticsEvents.productAnnouncementViewed(productAnnouncement)

  instrument(name, { ...data })
}

export const instrumentProductAnnouncementClicked = () => {
  const { name } = analyticsEvents.productAnnouncementClicked()

  instrument(name)
}
