import React, { VFC } from 'react'
import { useDispatch } from 'react-redux'

import { Box } from '@mindfulchefuk/design-system'
import { instrumentHomepageBannerClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument/instrumentBanners'
import { CMSImage } from '@mindfulchefuk/features/CMS/components/CMSImage'
import { useCMSPageBanner } from '@mindfulchefuk/features/CMS/hooks/useCMSPageBanner'
import { useCMSBannerRedirect } from '@mindfulchefuk/features/CMS/hooks/useCMSBannerRedirect'
import { CMSBannerLinkWrapper } from '@mindfulchefuk/features/CMS/components/CMSBannerLinkWrapper'
import { setDeliveryDate } from '@mindfulchefuk/actions/basketActions'

export const HomepagePromotionalBanner: VFC = () => {
  const dispatch = useDispatch()
  const banner = useCMSPageBanner({ page: 'page-homepage' })
  const { redirectURL, analytics, targetDeliveryDate } = useCMSBannerRedirect(
    banner?.redirectConfig
  )

  if (!banner) return null

  const eventParams = {
    bannerName: banner.title,
    campaignName: banner.campaign ? banner.campaign.title : '',
    hasDelivery: analytics.hasRecipeDelivery,
  }

  return (
    <CMSBannerLinkWrapper
      href={redirectURL}
      maxWidth="1140px"
      display="block"
      position="relative"
      my={{ base: 24, xl: 40 }}
      mx="auto"
      onClick={() => {
        instrumentHomepageBannerClicked(eventParams)
        dispatch(setDeliveryDate(targetDeliveryDate))
      }}
    >
      <Box backgroundColor="white" py={2}>
        <CMSImage
          data-testid="homepage-promotional-banner"
          src={banner.image.src}
          alt={banner.image.altText}
          sources={banner.image.sources}
        />
      </Box>
    </CMSBannerLinkWrapper>
  )
}
