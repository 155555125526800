import { subDays, format } from 'date-fns'
import { getImportantDates } from '@mindfulchefuk/features/Operations/utils/getImportantDates'
import { useGetDateDeliverabilities } from '@mindfulchefuk/features/Delivery/hooks/useGetDateDeliverabilities'
import { ORDER_RANGE } from '@mindfulchefuk/constants'

export const useAvailableDates = () => {
  const { firstBuildDate, maxPastDate, tomorrow } = getImportantDates()

  const { data: dateDeliverabilities, isLoading } = useGetDateDeliverabilities(
    new Date(tomorrow),
    new Date(maxPastDate)
  )

  const [recipeAvailableDates, recipeUnavailableDates] =
    dateDeliverabilities.reduce(
      ([availabeDates, unavailableDates], date) => {
        if (date.recipeDeliverable) {
          availabeDates.push(date.deliveryDate)
        } else {
          unavailableDates.push(date.deliveryDate)
        }
        return [availabeDates, unavailableDates]
      },
      [[], []]
    )

  const [shopAvailableDates, shopUnavailableDates] =
    dateDeliverabilities.reduce(
      ([availabeDates, unavailableDates], date) => {
        if (date.shopDeliverable) {
          availabeDates.push(date.deliveryDate)
        } else {
          unavailableDates.push(date.deliveryDate)
        }
        return [availabeDates, unavailableDates]
      },
      [[], []]
    )

  return {
    isLoading,
    recipes: {
      firstAvailableDeliveryDate: recipeAvailableDates[0] || firstBuildDate,
      availableDates: recipeAvailableDates,
      lastAvailableDeliveryDate:
        recipeAvailableDates[recipeAvailableDates.length - 1] ||
        format(subDays(tomorrow, ORDER_RANGE), 'YYYY-MM-DD'),
      unavailableDates: recipeUnavailableDates,
    },
    shop: {
      unavailableDates: shopUnavailableDates,
      availableDates: shopAvailableDates,
      firstAvailableShopDelivery: shopAvailableDates[0] || tomorrow,
    },
    deliverabilities: dateDeliverabilities,
  }
}
