import React, { VoidFunctionComponent } from 'react'
import NextLink from 'next/link'

import { Button, Box, Text } from '@mindfulchefuk/design-system'

import routesConfig from '@mindfulchefuk/config/routesConfig'
import { isAuthenticated } from '@mindfulchefuk/helpers/js/authentication'
import { logEvent } from '@mindfulchefuk/utils/analytics'

import { ANALYTICS_CATEGORIES_VALUES } from '@mindfulchefuk/constants'
import { useHasClientHydrated } from '@mindfulchefuk/hooks/useHasClientHydrated'
import { useSelector } from 'react-redux'
import { RootState } from '@mindfulchefuk/types/store'
import { getDiscountConfig } from '@mindfulchefuk/utils/getDiscountConfig'
import { useIsNewCustomer } from '@mindfulchefuk/features/Onboarding/hooks/useIsNewCustomer'
import { CTALocation } from '@mindfulchefuk/analytics/src/types'
import { instrumentRecipeCTAClicked } from '@mindfulchefuk/utils/analytics/amplitude/instrument'
import { useCMSHomepageVariant } from '@mindfulchefuk/features/CMS/hooks/useCMSHomepageVariant'
import { useIsBBCGoodFood } from '@mindfulchefuk/features/Homepage/utils/isHomepageVariant'

interface Props {
  'data-testid'?: string
  analytics: {
    category: ANALYTICS_CATEGORIES_VALUES
    action: string
    label: string
  }
  source?: CTALocation
  showCopy?: boolean
  text?: string
  isFullWidth?: boolean
}

const CTA: VoidFunctionComponent<Props> = ({
  analytics,
  source,
  'data-testid': testId,
  showCopy = true,
  text = 'Choose your recipes',
  isFullWidth = false,
}) => {
  const hasHydrated = useHasClientHydrated()
  const isLoggedIn = isAuthenticated() && hasHydrated
  const isBBCGoodFood = useIsBBCGoodFood()

  const savedVoucherCode = useSelector(
    (state: RootState) => state.basket.savedVoucherCode
  )

  const { isNewCustomer } = useIsNewCustomer()

  const { chooseRecipesQueryParams } = useCMSHomepageVariant()

  const { pathname } =
    isLoggedIn && !isNewCustomer
      ? routesConfig.calendar
      : routesConfig.chooseRecipes

  const discountConfig = getDiscountConfig(savedVoucherCode)
  const copy = isLoggedIn ? undefined : discountConfig?.totalDiscountSentence

  const pathnameWithQueryParams =
    chooseRecipesQueryParams && pathname === routesConfig.chooseRecipes.pathname
      ? pathname + chooseRecipesQueryParams
      : pathname

  const homepageVariant = isBBCGoodFood ? 'BBCGF ' : null

  return (
    <>
      <NextLink href={pathnameWithQueryParams} passHref>
        <Button
          as="a"
          variant={isBBCGoodFood ? 'bbc-good-food' : 'primary'}
          size="medium"
          data-testid={testId}
          onClick={() => {
            logEvent(analytics)
            if (source) instrumentRecipeCTAClicked(source, homepageVariant)
          }}
          fullWidth={isFullWidth}
        >
          {text}
        </Button>
      </NextLink>
      {showCopy && (
        <Text as="p" mt={8}>
          Fresh, quality ingredients
          {copy && (
            <>
              <Text as="span" display={{ base: 'none', md: 'inline' }}>
                {` | `}
              </Text>
              <Box as="br" display={{ base: 'block', md: 'none' }} />
              {copy}
            </>
          )}
        </Text>
      )}
    </>
  )
}

export default CTA
