import {
  analyticsEvents,
  PageSource,
  ProductType,
} from '@mindfulchefuk/analytics'
import { instrument } from '@mindfulchefuk/utils/analytics/amplitude/amplitudeAdapter'

export const instrumentCalendarBannerClicked = ({
  bannerName,
  campaignName,
  hasDelivery,
}: {
  bannerName: string
  campaignName?: string
  hasDelivery: boolean
}) => {
  const { name, data } = analyticsEvents.calendarBannerClicked({
    bannerName,
    campaignName,
    hasDelivery,
  })
  instrument(name, { ...data })
}

export const instrumentHomepageBannerClicked = ({
  bannerName,
  campaignName,
  hasDelivery,
}: {
  bannerName: string
  campaignName: string
  hasDelivery: boolean
}) => {
  const { name, data } = analyticsEvents.homepageBannerClicked({
    bannerName,
    campaignName,
    hasDelivery,
  })
  instrument(name, { ...data })
}

export const instrumentR2GBannerClicked = ({
  bannerName,
  campaignName,
  pageSource,
  productCategory,
}: {
  bannerName: string
  campaignName: string
  pageSource: PageSource
  productCategory: ProductType
}) => {
  const { name, data } = analyticsEvents.r2gBannerClicked({
    bannerName,
    campaignName,
    pageSource,
    productCategory,
  })
  instrument(name, { ...data })
}

export const instrumentChooseRecipesBannerClicked = ({
  bannerName,
  campaignName,
  pageSource,
  customerPreferences = [],
}: {
  bannerName: string
  campaignName: string
  pageSource: PageSource
  customerPreferences?: string[]
}) => {
  const { name, data } = analyticsEvents.chooseRecipesBannerClicked({
    bannerName,
    campaignName,
    pageSource,
    customerPreferences,
  })
  instrument(name, { ...data })
}

export const instrumentProductDetailsBannerClicked = (bannerName: string) => {
  const { name, data } = analyticsEvents.productDetailsBannerClicked({
    productCategory: ProductType.RECIPES,
    productType: 'recipe',
    bannerName,
  })
  instrument(name, { ...data })
}
